enum TimeUnit {
  Milliseconds = 1,
  Seconds = 1000,
  Minutes = 60 * 1000,
  Hours = 60 * 60 * 1000,
  Days = 24 * 60 * 60 * 1000,
}

interface UseDate {
  subMonths: (date: Date, monthsToSubtract: number) => Date;
  differenceWith: (unit: TimeUnit, dateLeft: Date, dateRight: Date) => number;
  differenceInMonths: (dateLeft: Date, dateRight: Date) => number;
  formatDistanceToNow: (targetDate: Date, options?: Intl.RelativeTimeFormatOptions) => string;
}

export const dateTime = new Intl.DateTimeFormat('nl-NL', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
});

export const dayMonth = new Intl.DateTimeFormat('nl-NL', {
  day: '2-digit',
  month: 'long',
});

export const weekdayMonth = new Intl.DateTimeFormat('nl-NL', {
  weekday: 'long',
  day: '2-digit',
  month: 'long',
});

export const timeOnly = new Intl.DateTimeFormat('nl-NL', {
  hour: '2-digit',
  minute: '2-digit',
});

export const monthOnly = new Intl.DateTimeFormat('nl-NL', {
  month: 'long',
});

export const dayMonthYear = new Intl.DateTimeFormat('nl-NL', {
  day: '2-digit',
  month: 'long',
  year: 'numeric',
});

export function formatTime(from: string, to: string) {
  const fromDate = new Date(from);
  const toDate = new Date(to);

  const fromHour = fromDate.getUTCHours().toString().padStart(2, '0');
  const fromMinutes = fromDate.getUTCMinutes().toString().padStart(2, '0');
  const toHour = toDate.getUTCHours().toString().padStart(2, '0');
  const toMinutes = toDate.getUTCMinutes().toString().padStart(2, '0');

  if (fromHour === '00' && fromMinutes === '00' && toHour === '23' && toMinutes === '59') {
    return 'Hele dag';
  }

  return `${fromHour}:${fromMinutes} - ${toHour}:${toMinutes}`;
}

export function formatTimeFormDates(from: Date, to: Date) {
  const fromHour = from.getUTCHours().toString().padStart(2, '0');
  const fromMinutes = from.getUTCMinutes().toString().padStart(2, '0');
  const toHour = to.getUTCHours().toString().padStart(2, '0');
  const toMinutes = to.getUTCMinutes().toString().padStart(2, '0');

  return `${fromHour}:${fromMinutes} - ${toHour}:${toMinutes}`;
}

export function getFormattedTime(dateString: string) {
  const date = new Date(dateString);

  const fromHour = date.getUTCHours().toString().padStart(2, '0');
  const fromMinutes = date.getUTCMinutes().toString().padStart(2, '0');

  return `${fromHour}:${fromMinutes}`;
}

export function getFormattedDateTime(date: string, time: string) {
  return date + 'T' + time;
}

export function formatHoursMinutes(input: { hours: number; minutes: number }) {
  const hour = input.hours.toString().padStart(2, '0');
  const minute = input.minutes.toString().padStart(2, '0');

  return `${hour}:${minute}`;
}

export function formatDayMonth(date: Date) {
  const day = date.getUTCDate();
  const month = date.toLocaleString('nl-NL', { month: 'long' });

  return `${day} ${month}`;
}

export function formatInputDate(isoString: string) {
  return isoString.split('T')[0];
}

export function sameDay(d1: Date, d2: Date) {
  return d1.getUTCFullYear() === d2.getUTCFullYear() && d1.getUTCMonth() === d2.getUTCMonth() && d1.getUTCDate() === d2.getUTCDate();
}
export function useDate(): UseDate {
  // const subtractedDate = subMonths(new Date(2023, 7, 22), 3)
  // console.log(subtractedDate) // Output: Sun May 22 2023 00:00:00 GMT+0000 (Coordinated Universal Time)
  function subMonths(date: Date, monthsToSubtract: number): Date {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() - monthsToSubtract);
    return newDate;
  }

  // const differenceInHours = differenceWith(TimeUnit.Hours, new Date(2023, 7, 22, 12, 0, 0), new Date(2023, 7, 20, 8, 0, 0));
  // console.log(differenceInHours); // Output: 52
  function differenceWith(unit: TimeUnit, dateLeft: Date, dateRight: Date): number {
    const timeDifference = dateLeft.getTime() - dateRight.getTime();
    return timeDifference / unit;
  }

  // const monthsDifference = differenceInMonths(new Date(2023, 7, 22), new Date(2023, 0, 15))
  // console.log(monthsDifference) // Output: 7
  function differenceInMonths(dateLeft: Date, dateRight: Date): number {
    const yearDiff = dateLeft.getFullYear() - dateRight.getFullYear();
    const monthDiff = dateLeft.getMonth() - dateRight.getMonth();

    return yearDiff * 12 + monthDiff;
  }

  // const formattedDistance = formatDistanceToNow(new Date(2023, 7, 22))
  // console.log(formattedDistance) // Output: "in 1 year"
  function formatDistanceToNow(targetDate: Date, options?: Intl.RelativeTimeFormatOptions): string {
    const now = new Date();
    const diffMilliseconds = targetDate.getTime() - now.getTime();

    const defaultOptions: Intl.RelativeTimeFormatOptions = {
      localeMatcher: 'best fit',
      numeric: 'auto',
      style: 'long',
    };

    const mergedOptions = { ...defaultOptions, ...options };

    const rtf = new Intl.RelativeTimeFormat(undefined, mergedOptions);
    return rtf.format(Math.round(diffMilliseconds / 1000), 'second');
  }

  return {
    subMonths,
    differenceWith,
    differenceInMonths,
    formatDistanceToNow,
  };
}
